input:focus{
  outline: none;
}

.container {
  height: 100vh;
  display: flex;
  width: 100%;
}
.content {
  height: 100vh;
  background-image: url("../../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  width: 100%;
}
.image {
  height: 80px;
  margin: 20px;
}

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  background-color: rgba(3, 105, 3, 0.203);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #2a5041 ;
  border-radius: 10px;
} */

.popupContainer {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  /* border: 2px solid red; */
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.popup{
  background-color: #151515;
  border: 1px solid white;
  border-radius: .5rem;
  padding: 1rem 2rem;
  color: #86b393;
  height: 85vh;
}

.heading {
  /* border: 2px solid  red; */
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin: .5rem 0;
}

.name{
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.cosigner {
  text-decoration: underline;
}

.fileUploader {
  display: flex;
  /* align-items: center; */
  gap: .2rem;
}

.uploads {
  cursor: pointer;
  text-decoration: underline;
}

.instructions{
  text-decoration: underline;
  text-align: center;
  cursor:text;
  /* margin-top: .5rem; */
}

.btn {
  background-color: #2a5041;
  color: white;
  margin: .2rem 0;
  width: 100%;
  padding: .5rem;
  font-size: 1.2rem;
}

/* gems popup */

.gemsContainer {
  /* background-color: rgba(255, 255, 255, 0.684); */
  background-color: rgba(0, 0, 0, 0.7);

}

.gemsPopup {
  background-color: #f2f2f2;
  border: 5px solid rgb(114, 173, 140);  
  width: 800px;
  height: 90%;
  overflow-y: auto;
  border-radius: 15px;
}

.gemBox{
  color: #151515;
}

.paginate_btn{
  background-color: #2a5041;
  color: white;
  font-size: 2rem;
  border-radius: 5px;
  border: 1px solid white;
}

.confirmPopup {
  height: auto;
}