@import url('../../index.css');

.custom-header {
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--background-color);
  padding: 10px;
  border-bottom: 1px solid var(--text-color);
}
.container {
    background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
  }

.image{
  height: 60px;
  margin: 20px;
}


