body {
  margin: 0;
}


@media (max-width: 768px) {
  .customTile {
    width: 350px; 
    height: 400px; 
  }
}


.sidebar {
  background-color: #80a38c;
  width: 25%; 
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0; 
  overflow-y: auto;
  padding: 20px;
  z-index: 1000; 
  transition: transform 0.3s ease; 
  transform: translateX(100%); 
}

.show {
  transform: translateX(0); 
}

.CloseIcon{
  color: #fff;
  cursor: pointer;
}

.closeIconContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px; 
}

.sidebarList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebarList li {
  padding: 10px 0;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebarList li:hover {
  background-color: #BCC7C2; 
}


.navbar{
  background-color: #80a38c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.navbarToggle{
  width: 30px;
  color: #fff;
  height: 25px;
}

.tile1Button{
  margin-right: 10px;
  background-color: #BCC7C2;
  color: #395849;
  padding: 0 20px;
  height: 30px;
  border-radius: 2px;
  font-size: 13px;
}

.tile8Buttons{
  margin-right: 10px;
  background-color: #395849;
  color: #BCC7C2;
  padding: 0 20px;
  height: 30px;
  border-radius: 2px;
  font-size: 13px;
}

.videoSection {
  background: linear-gradient(
    269deg,
    rgba(11, 27, 55, 0.4) 1.07%,
    rgba(1, 10, 26, 0.4) 17.36%,
    rgba(31, 41, 61, 0) 98.79%
  );
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.16) inset;
  backdrop-filter: blur(50px);
  width: 100vw; 
  height: 80vh;
  flex-shrink: 0;
  /* display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover; */
}

.back {
  left: -44px;
}

.next {
  right: -44px;
}

.sliderButton {
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  background-color: white;
  padding: 50px;
}

.content {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;  
}
.container {
  padding: 20px 20px;
}




.navbarLogo{
  height: 50px;
  padding: 3px;
}

.tile{
  background-color: #E3E1DC;
  padding: 10px;
  border-radius: 6px;
  max-height: 100%;
}
.replay{
  width: 65px;
  height: 65px;
}
.tile4{
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    /* flex: 1; */
    height: 50%;
  
}




.tile1Container1{
  padding: 8px 20px 0px 20px;
}

.tile1{
  background-color: #E3E1DC;
  border-radius: 6px;
  max-height: 100%;
}

.heading{
  color: black;
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 10px;
  font-family: "Signika";
  text-decoration: underline;
}

.tile1Box{
  display: flex;
  flex-direction: column;
  align-items: center;
  place-items: center;
  justify-content: center;
  cursor: pointer;
  height: 70%;
}

.tile1Img{
  width: 59px;
  height: 58px;
}

.tile6Img{
  width: 50px;
  height: 40px;
}


.downloadlogo{
  width: 30px;
  height: 30px;
}

.title3Box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error{
  font-size: 7px;
  color: red;
  margin-top: 0px;
}

.inputRow {
  display: flex;
  margin-bottom: 10px; 
}

.tile2desp{
  font-size: 10px;
}

.tileheading{
  font-size: 10px;
  text-align: center;
}

.tile6heading{
  font-size: 11px;
}

.tile2Timing{
  background-color: #CFD8E4;
  margin-right:5px ;
  padding: 2px 5px;
  font-size: 8px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile4Result{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* do not uncomment this css if you want to then make sure to fix all the affected textarea tags */
/* .textarea{
  width: 100%;
  height: 60%;
  padding: 20px;
  color: #456B59;
  border: 2px solid #456B59;
}

textarea::placeholder {
  color: #456B59;
  font-size: 10px;
  position: absolute; 
  font-weight: 600;
  top: 70%; 
  left: 20px;
} */

.tile6Box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inviteBox{
  border:1px solid #456B59;
  height: 20px;
}

.tileButtons{
  background-color: #3B755E;
  color: #fff;
  padding: 0 8px;
  height: 20px;
  border-radius: 2px;
  font-size: 10px;
}
.tileButton{
  background-color: #3B755E;
  color: #fff;
  padding: 0 11px;
  height: 20px;
  border-radius: 2px;
  font-size: 10px;
}

.title3Box{
  display: flex;
}
.tile3Buttons{
  display: flex;
}
.tile3Button{
  background-color: #fff;
  color: #395849;
  padding: 0 8px;
  height: 18px;
  border-radius: 2px;
  font-size: 8px;
}

.tile3Img{
  width: 32px;
  height: 32px;
}

.tile3Descprition{
  font-size: 11px;

}

.description{
  display: flex;
  justify-content: start;
}

.tile4Button{
  background-color: #356C58;
  color: #fff;
  margin-top: 6px;
  padding: 10px;
  border-radius: 2px;
  font-size: 12px;
}
.tilesButton{
  background-color: #356C58;
  color: #fff;
  padding: 5px 10px;
  height: 23px;
  border-radius: 2px;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tile8Box{
  background-color: #fff;
  width: 110%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.tile8heading{
  color: black;
  font-size: 0.9rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.tile8Description{
  display: flex;
  width: 100%;
}
.tile8head2{
  font-size: 1.8rem;
  color: #000;
  font-weight: 500;
}

.tile8head3{
  font-size: 0.5rem;
}

.tile8Img{
  width: 40px;
  height: 40px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.overlayContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  height: 95%;
}

@media only screen and (max-width: 1013px) {
  .overlayContent {
    padding: 15px;
    width: 95%;
  }
}


.closeBtn {
  position: absolute;
  top: -10px;
  right: 4px;
  font-size: 30px;
  cursor: pointer;
}


.overlayIframe {
  width: 97.5%;
  height: 100%;
}

.tile4Img{
  width: 160px;
  height: 80px;
}
.calendlyContainer {
            width: 100%;
            margin: 0 auto;
            padding: 10px;
            height: 100%;

        }


        .desktopView {
          display: flex;
          justify-content: center; 
          align-items: center;
          height: 100vh; 
        }

.equNoBtn {
  background-color: #ff0000ad;
  color: #fff;
  padding: 0 8px;
  height: 20px;
  border-radius: 2px;
  font-size: 10px;
  margin-left: 5px;
}
.overflowContainer {
  height: 70%; 
  overflow-y: auto;
}


.customTile {
  width: 100%; 
  height: 40vh;
  overflow-y: auto;
}

.formContainer{
  background-color: #E3E1DC;

}

.scheduledTxt{
  background-color: #ffffff;
  color: #000000;
  padding: 5px 10px;
  height: 23px;
  border-radius: 2px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.learnMoreImg {
  height: 55px;
  margin-bottom: 5px;
}

.entityBtn {
  margin-left: .5rem;
  font-size: .75rem;
  height: 1.5rem;
}

.equityInput {
  font-size: .9rem;
  padding: .5rem;
  height: 1.4rem;
}
::-webkit-scrollbar {
  height: 10px;
}
