.page{
    padding: 8px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    /* min-height: 100vh; */
    background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
    background-size: cover;
}
.header{
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 15px;
}
.span{
    font-size: 1.2rem;
    color: white;
}
.formHeader{
    font-size: 1.7rem;
    color: white;
    margin: 2rem 0;
}
.image{
    height: 80px;
    margin: 20px;
}
.logoutButton{
    width: 153px;
    height: 42px;
    background-color: rgb(59 117 94);
    color: white;
    font-weight: bold;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    width: 70%;
    background-color: #9bccb3;
    margin: 0 auto;
    border-radius: 7px;
    margin-top: 20px;
}
.link{
    color: darkblue;
    text-align: center;
    margin-bottom: 40px;
}