@import url('../../index.css');

.custom-header {
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--background-color);
  padding: 10px;
  border-bottom: 1px solid var(--text-color);
}

.container {
  background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat; 
  padding-left: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.image{
  height: 60px;
  margin: 20px;
}

.pinTitle {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Raleway";
  font-size: 2rem;
  font-weight: 500;
  line-height: 48px;
  text-transform: capitalize;
  margin: 0;
  position: absolute;
  top: -102px;
}
.textCss{
  display: flex;
  align-items: end;
  color: rgba(255, 255, 255, 0.8);
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 16px;
  gap:10px;
}
.inputBox {
  width: 100%;
  height: 40px;
  background-color: #3B755E;
  padding-left: 2px;
}
.input {
  height: 44px;
  background-color: transparent;
  outline: none;
  color: #ada4a4;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.216px;
  padding: 12px 16px;
  margin-top: 4px;
}
.submit {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
.logo {
  position: absolute;
  top: 50px;
  left: 50px;
  min-width: 341px;
  width: 10vw;
  height: auto;
}


@keyframes shake {
  0% { transform: translateX(0); }
  10%, 90% { transform: translateX(-10px); }
  20%, 80% { transform: translateX(10px); }
  30%, 50%, 70% { transform: translateX(-10px); }
  40%, 60% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.shakeInput {
  animation: shake 0.8s;
}

.heading{
  font-size :1rem;
  text-align: start;
  color: white;
  width: 100%;
  padding-bottom: 2px;
}
.head1{
  color: hsl(156, 63%, 66%);
}