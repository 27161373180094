.forgotBtnStyle {
  background-color: #3B755E;
  color: white;
  padding: 0 20px;
  height: 40px;
  font-size: 13px;
  /* font-weight: 00; */
}
.light4{
  background-color: #E3E1DC;
}
.light5{
  background-color:#fff ;
}
.light6{
  background-color:#E5E7EB ;
}