.container {
  height: 100vh;
  display: flex;
  width: 100%;
}
.content {
  /* height: 100vh; */
  background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  width: 100%;
}
