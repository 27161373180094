.inputContainer{

}

.input{
    border: 1px solid #86B393;
}
.invalid{
    border: 1px solid red;
}
