.container {
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.8);
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  gap: 10px;
  width: 100%;
}
.input {
  height: 36px;
  background-color: white;
  outline: none;
  color: #3b755e;
  font-family: Raleway;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}

.styles.input::placeholder {
  font-size: 16px !important;
}

.inputBox {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background-color: #fff;
}
.error {
  color: red;
  font-weight: 600;
  font-size: 13.5px;
  padding-left: 2px;
  margin-top: -10px;
}
