.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #E3E2DB;
    border: none;
    padding :20px;
    min-width: 32em;
    max-width: 45%;
    min-height: 200px;
    max-height: 80%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    font-size: 1rem; 
    position: relative;
    box-sizing: border-box;
  }


  .modalContent {
    font-size: 1.2rem;
  }
  
  .modalText {
    margin-bottom: 20px; 
  }
  
  .modalButton {
    margin-top: auto; 
  }
  
  .shakeInput {
    animation: shake 0.4s ease-in-out;
  }
  
  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  