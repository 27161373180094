.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.overlayContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: auto;
    min-width: 300px;
    height: auto;
    max-height: 95%;
    overflow-y: auto;
}

.inputBox {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    margin: 1rem .5rem ;
}

.labels {
    margin: .2rem 0 ;
}

.textInputs {
    color: rgb(70, 70, 70);
    padding: .3rem .7rem ;
    border: 1px solid #CCCCCC;
    border-radius: .5rem;
}

.selectInputs {
    height: 2rem;
}

.inputBox > span {
    text-align: right;
}

.btn {
    margin: 1rem 0 0 1rem;
    font-weight: bold;
    color: rgb(67, 125, 125);
}

.closeBtn {
    position: absolute;
    top: 0;
    right: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
}