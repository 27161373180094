  .content{
    height: 100%;
    background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
    background-position: center;
    background-repeat: no-repeat; 
    padding: 20px;
    overflow-y: auto;
  }

  .mydocButton{
    color: #fff;
    background-color: #395849;
    padding: 0 8px;
    height: 18px;
    border-radius: 2px;
    font-size: 10px;
  }
 
  .mydocimg{
    width: 32px;
    height: 32px;
  }
  .myFolderImg{
    width: 32px;
    height: 32px;
    padding: 4px;
  }

  .customtable {
    border-collapse: collapse;
    width: 100%;
  }
  

  .customtable tr {
    border: 1px solid #dddddd; 
    /* text-align: left; */
  }
  
  .customtable td {
    border: 1px solid #dddddd; 
    padding: 8px;
    text-align: left;
  }
  