@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap");

/* :root {
  --font-size: 2rem;
  --font-family: Arial, sans-serif;
  --font-weight: normal;
  --background-color: white;
  --text-color: black;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100vw;
  flex-direction: column;
}
.center button {
  width: 100px;
  height: 40px;
}
.dark {
  background: #000;
  color: #fff;
}
.light {
  background: lightblue;
  color: #000;
}


body {
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
}
p {
  font-size: var(--font-size);
  font-family: var(--font-family);
}