.button {
  padding: 4px 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2.5px);
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.288px;
  color: #fff;
}
.light {
  background:#356C58;
}
.back {
  background: linear-gradient(28deg, #595a59 13.91%, #3d3d3c 79.49%);
}
.green{
  background-color: #3B755E;
  color: white;
  padding: 0 20px;
  height: 40px;
  font-size: 13px;
}

.light1{
  background:#80A38C;
}