body{
    background-color: rgb(215, 215, 215);
}

.formContainer {
    display: flex;
    width: 65%;
    margin: auto;
    flex-direction: column;
}

.row1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: .7rem 0;
}

.inputBox1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 1rem;
}

.labels {
    font-size: 1.2rem;
    color: #808080;
}

.inputs {
    border: 1px solid #80808076;
    border-radius: .4rem;
    padding: .3rem 1rem;
    font-size: 1.2rem;
}


/* media query */


@media (max-width: 1000px) {
    .formContainer {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .formContainer {
        width: 90%;
    }
    .row1 {
        margin: 0;
        flex-direction: column;
    }
    .inputBox1 {
        margin: .5rem 0;
    }
}
