/* Modal Styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.requiredAsterisk{
  font-size: 20px;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  /* max-height: 90%;  */
  height: 90%;
  overflow-y: auto; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.formContainer {
  width: 100%;
}

.inputGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.inputGroup label {
  flex: 1;
  font-size: 0.875rem;
  margin-right: 10px;
}

.inputGroup input {
  flex: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitButton {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.submitButton:hover {
  background: #0056b3;
}

/* Tile Styles */
.tile {
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 20px;
}

.tile h2 {
  margin-bottom: 20px;
}



.closeButton {
  /* position: absolute; */
  top: 20px;
  right: 10px;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 2rem;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.errorMessage{
  color: red;
  display: flex;
  justify-content: end;
  font-size: 13px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.modalTitle{
  font-family: "Signika";
  font-weight: "500";
  font-size: 1.2rem;
}

.tileButtons{
  background-color: #3B755E;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  border-radius: 2px;
  font-size: 13px;
  margin-right: 8px;
}
.tileButton{
  background-color: #3B755E;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  border-radius: 2px;
  font-size: 13px;
  margin-right: 8px;
}
.formLabel{
  font-weight: "800";
  font-family: "Raleway";
  width: 30%;
}



.inviteBox{
  width: 100%;
}
.selectBox {
  padding: 8px;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 4px;
  outline: none;
  font-size: 11px;
  width: 100%;
  box-sizing: border-box;
}


.tooltip {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.infoIcon:hover + .tooltip {
  display: block;
}

.infoIcon {
  margin-left: 5px;
  color: #999;
  cursor: pointer;
}
