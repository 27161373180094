.content {
    height: 100vh;
    background-image: url("../../../assets/images/Green_Solar_Background_1920x1080.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }