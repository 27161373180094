.page {
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  /* min-height: 100vh; */
  background-image: url("../../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover;
}
.page {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 15px;
  }
  /* &::-webkit-scrollbar {
      display: none;
    } */
}
.header {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
}
.span {
  font-size: 1.2rem;
  color: white;
}
.formHeader {
  font-size: 1.7rem;
  color: white;
  margin: 2rem 0;
}
.image {
  height: 80px;
  margin: 20px;
}
.switchBox {
  display: flex;
  align-items: center;
  gap: 10px;
}
.logoutButton {
  width: 153px;
  height: 42px;
  background-color: rgb(59 117 94);
  color: white;
  font-weight: bold;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  width: 70%;
  background-color: #9bccb3;
  margin: 0 auto;
  border-radius: 7px;
  margin-top: 20px;
}
.formLineFull {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: auto;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}
.inputLabel {
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10%;
}
.controller {
  width: 23%;
  margin-right: 22%;
  height: 30px;
  border-radius: 5px;
  border-width: 0px;
  font-size: 16px;
  padding: 0 10px;
}

.inputBox {
  width: 100%;
  /* margin-right: 22%; */
  height: 30px;
  border-radius: 5px;
  border-width: 0px;
  font-size: 16px;
  padding: 0 10px;
}
.inputContainer {
  position: relative;
  width: 23%;
  margin-right: 22%;
  height: 30px;
  display: flex;
  flex-direction: column;
}
.error {
  position: absolute;
  bottom: -19px;
  font-size: small;
  color: red;
  width: max-content;
}
.loaderBox {
  margin-bottom: 40px;
}
