.content {
  height: 100vh;
  background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80vh; 
  width: 50%;
}
.image{
  height: 60px;
  margin: 20px;
}

.imageContainer {
  flex: 1;
  height: 100%;
}

@media (max-width: 768px) {
  .imageContainer {
    display: none;
  }
}

.imageContainer img {
  flex: 1;
  height: 100%;
}

.signupForm {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
}

 h2 {
  text-align: center;
  color: #fff;
  font-size: 1.9rem;
  font-weight: 400;
  font-family: Signika;
}


.allInput { 
  outline: none;
  color: #000;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding: 7px;
  width: 100%; 
  border: 3px solid #4C725F;
}
.selectInput { 
  outline: none;
  color: #000;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  width: 100%; 
  border: 3px solid #4C725F;
}

.inputBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; 
}

form{
  width: 86%;
}

.button{
  background-color: #4C725F;
  color: white;
  height: 40px;
  padding: 0 2rem;
  font-size: 14px;
  margin-right: 8px;
}

.radioButtonGroup {
  display: flex;
  align-items: center;
}

.radioButtonGroup input[type="radio"] {
  margin: 10px;
  background-color: #77B991;
  color: #77B991;
}

.radioButtonGroup label {
  color: #77B991;
  font-size: 1rem;
}

.advisorContainer {
  display: flex;
  flex-direction: column;
  align-items: start; 
}

.advisorContainer {
  margin-right: 10px;
  margin-left: 8px;
  font-size: 1rem; 
  color: #77B991;
}

