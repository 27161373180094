
.modalButton {
  margin-left: 10px; 
}

.modalText {
  margin-bottom: 10px; 
  font-weight: 600;
  font-size: 1.6rem;
}

.modalButtonContainer {
  display: flex;
  justify-content: space-between;
}

.container{
  padding: 10px 60px;
}

.modaldecription {
  font-size: 13px;
  color: rgba(0, 0, 0);
  align-items: center;
  text-wrap: pretty;
  overflow-y: auto;
  max-height: 300px; 
}

.modalDesc{
align-items: center;
align-content: center;
}


.modalButton1 {
  background-color: #3B755E;
  padding: 3px 0px;
  font-weight: 400;
  font-size: 13px;
  color: white;
  width: 6rem;
}

.modalButton {
  background-color: #BCD8C5;
  padding: 3px 0px;
  width: 6rem;
  font-size: 13px;
}

.modalContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
