.videoSection {
  background: linear-gradient(
    269deg,
    rgba(11, 27, 55, 0.4) 1.07%,
    rgba(1, 10, 26, 0.4) 17.36%,
    rgba(31, 41, 61, 0) 98.79%
  );
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.16) inset;
  backdrop-filter: blur(50px);
  width: 100vw; 
  height: 80vh;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.back {
  left: -44px;
}

.next {
  right: -44px;
}

.sliderButton {
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  background-color: white;
  padding: 50px;
}

.content {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
