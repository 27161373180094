.container {
    padding-left: 20px;
    padding-right: 199px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    flex: 1;
  }

  .image{
    height: 60px;
    margin: 20px;
  }

  .content{
    height: 100vh;
    background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pinTitle {
    color: rgba(255, 255, 255, 0.8);
    font-family: "Raleway";
    font-size: 2rem;
    font-weight: 500;
    line-height: 48px;
    text-transform: capitalize;
    margin: 0;
    position: absolute;
    top: -102px;
  }
  .textCss{
    display: flex;
    align-items: end;
    color: rgba(255, 255, 255, 0.8);
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 16px;
    gap:10px;
  }
  .inputBox {
    background-color: #3B755E;
    width: 100%;
    padding-left: 2px;
  }
  .input {
    background-color: white;
    outline: none;
    color: black;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.216px;
    padding: 7px 16px;
    width: 100%;
    height: 36px;
    /* margin-top: 4px; */
  }
  
  .loginForm {
    margin-top: 10px;
    margin-inline-start: 50px;
    width: 30vw;
    height: 20vh;
    position: relative;
  }
  .submit {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
  .logo {
    min-width: 341px;
    width: 40vw;
    height: auto;
  }
  
  
  .modalButton {
    margin-left: 10px; 
  }
  

  .modalText {
    margin-bottom: 10px; 
    font-weight: 600;
    font-size: 1.4rem;
 text-align: center;

  }
  
  .modalButtonContainer {
    display: flex;
    margin-top: 25px;
    justify-content: center;

  }

  .modaldecription {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.586);
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
  }

.modalDesc{
  align-items: center;
  align-content: center;
}

  .modalButton1 {
    margin-left: 10px;
    background-color: #3B755E;
    color: white;
    padding: 3px 30px;
    font-weight: 400;
  }
  
  .modalContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .forgotBtnStyle {
    background-color: #3B755E;
    color: white;
    padding: 0 20px;
    height: 40px;
    font-size: 13px;
    /* font-weight: 00; */
  }
  .logo {
    position: absolute;
    top: 50px;
    left: 50px;
    min-width: 341px;
    width: 10vw;
    height: auto;
  }
