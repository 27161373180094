.centerdivision {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
}
.right {
  width: 100%;
}
.left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.info {
  margin: 44px 0;
}
.name {
  color: rgba(255, 255, 255, 0.8);
  font-family: Raleway;
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: capitalize;
  flex: 1;
}
.logo {
  display: flex;
  align-items: center;
  min-width: 341px;
  height: auto;
}

.disText {
  color: #000;
  font-family: Raleway;
  font-weight: 400;
  font-size: 0.7rem;
}

.disTextHead {
  color: #000;
  font-family: Raleway;
  font-size: 1.1rem;
  font-weight: 400;
}

.btn1{
  /* margin-left: 1192px; */
  border-radius: 11px;
  border: none;
}

.btn2{
  margin-left: 10px;
  border-radius: 11px;
  border: none;
}

.btnTop1{
  /* margin-left: 982px; */
  border-radius: 11px;
  border: none;
}

.btnTop2{
  margin-left: 10px;
  border-radius: 11px;
  border: none;
}

.scrollable-table-wrapper {
  overflow-x: auto;  
  overflow-y: auto;  
  max-height: 65vh;
  width: 100%;
}
.tableContainer{
  overflow-x: auto;
  scrollbar-width: 8px;
  max-height: 90vh;
}
.tableContainer::-webkit-scrollbar {
  width: 0px; 
}

.tableContainer::-webkit-scrollbar-thumb {
  /* background-color: #888;  */
  background-color: white; 
  border-radius: 5px; 
}

.tableContainer::-webkit-scrollbar-track {
  /* background-color: #f0f0f0;  */
  background-color: #356C58; 
  border-radius: 5px;
}
.btnResponsive{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.backBtn{
  display: flex;
  justify-content:end
}

@media screen and (max-width: 767px) {
  .btnResponsive{
    flex-direction: column-reverse;
    justify-content: center;
  }
  .backBtn{
    padding-bottom: 1rem;
  }
}

