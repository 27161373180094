

body {
    background-color: #0C0C0E;
  }
  
  .container {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .step {
    border-radius: 100%;
    position: relative;
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .innerCircle{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background-color: rgb(119,185,145)
  }
  .innerCircleIncomplete{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background-color: rgb(113, 140, 129)
  }
  .completed {
    border: 1px solid rgb(119,185,145);
    padding: 1px;
  }
  .pending {
    border: 1px solid rgb(119,185,145);
  }
  .steps{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .completedLine {
    /* width: 10vw; */
    width: 25%;
    border: 0.5px solid rgba(255, 255, 255,0.6);
    box-shadow:
          1px -1px 1px  rgba(255, 255, 255,0.4),
          1px 1px 1px  rgba(255, 255, 255,0.2),
          1px -1px 1px  rgba(76, 175, 80, 0.3),
          1px 1px 1px  rgba(76, 175, 80, 0.1);
  }

  .line{
    flex: 1;
    width: 25%;
  }
  .pendingLine {
    /* width: 10vw; */
    width: 25%;
    box-shadow:
          1px 1px 1px 0.5px rgba(255, 255, 255,0.4),
          1px 1px 1px 0.5px rgba(255, 255, 255,0.2),
          1px 1px 1px 0.5px rgba(76, 175, 80, 0.3),
          1px 1px 1px 0.5px rgba(76, 175, 80, 0.1);
  }
  .count {
    color: #fff;
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
  }
  .completedCheck {
    color: #fff;
  }
  .stepData{
    font-weight: 500;
    color: rgb(109,173,135);
    min-width: 7.5rem;
    text-align: center;
  }
  .stepDataIncomplete{
    font-weight: 500;
    color: rgb(113, 140, 129);
    width: 100%;
    text-align: center;
  }
  /* @media (max-width: 750px) {
    .container {
      display: flex;
      align-items: center;
    }
    .step {
      height: 34px;
      width: 34px;
    }
    .completedLine {
      width: 53px;
    }
    .pendingLine {
      width: 53px;
    }
    .count {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 400;
    }
  }
  @media (max-width: 400px) {
    .stepData{
      font-size: 12.5px;
    }
  } */
  