@import url('../../index.css');

.custom-header {
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--background-color);
  padding: 10px;
  border-bottom: 1px solid var(--text-color);
}
.container {
    background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
  }

.image{
  height: 60px;
  margin: 20px;
}
.popupContainer {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup{
  background-color: white;
  border: 1px solid white;
  width: 50%;
  height: 50%;
  border-radius: 2rem;
  padding: 1rem 2rem;
  color: #86b393;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.overlayContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  min-width: 300px;
  height: 90%;
  overflow-y: auto;
}

.titleBox {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem ;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: .5rem;
  font-size: 1.5rem;
  cursor: pointer;
}


.labels {
  margin: .2rem 0 ;
  font-size: 1.1rem;
}

.inputs {
  color: rgb(93, 93, 93);
}

::-webkit-scrollbar {
  width: 10px;
  height: 14px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  background-color: rgb(249, 247, 247);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #C7C7C7 ;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #828181 ;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.infoIcon:hover + .tooltip {
  display: block;
}