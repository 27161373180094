.videoSection {
  background: linear-gradient(
    269deg,
    rgba(11, 27, 55, 0.4) 1.07%,
    rgba(1, 10, 26, 0.4) 17.36%,
    rgba(31, 41, 61, 0) 98.79%
  );
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.16) inset;
  backdrop-filter: blur(50px);
  width: 100vw; 
  height: 80vh;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.back {
  left: -44px;
}
.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
}



.next {
  right: -44px;
}

.sliderButton {
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  background-color: white;
  padding: 50px;
}

.image{
  height: 60px;
  margin: 20px;
}


.content {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}


.input1 {
  background-color: white;
  outline: none;
  color: #3D735F;
  font-family: Raleway;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.216px;
  padding: 9px 16px; 
  border: none; 
  width: 100%; 
  border:1px solid #3D735F; 
   /* margin-right:10px; */
}

.inputBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px 0px ;
}

.inputRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; 
}

.btn {
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  background-color: #3B755E;
  color: white;
  height: 30px;
  font-size: 13px;
  align-items: center;
  padding: 0 25px;
}

.btn1 {
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  background-color: #3B755E;
  color: white;
  height: 30px;
  font-size: 13px;
  align-items: center;
  padding: 0 25px;
  cursor: not-allowed;
}


/* .info {
  margin-top: 44px;
  margin-bottom: 32px;
}
.description {
  color: rgba(255, 255, 255, 0.8);
  font-family: Raleway;
  font-size: 48px;
  font-weight: 500;
  text-transform: capitalize;
  width: 730px;
  margin: 44px 0;
}
.contentRow {
  display: flex;
  justify-content: center;
  width: 100%;
}
.inputRow {
  display: flex;
  gap: 24px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.responsiveFont{
  
}
@media screen and (max-width: 400px) {
  .responsiveFont{
    font-size: 14px;
    padding: 7px 11px;
  }
} */
