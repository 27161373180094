body {
  margin: 0;
}
.getClass {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100, "YTLC" 500;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.overlayContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  height: 95%;
}

.overlayContent2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  min-width: 300px;
  height: 90%;
  overflow-y: auto;
}

.closeBtn {
  position: absolute;
  top: -10px;
  right: 4px;
  font-size: 30px;
  cursor: pointer;
}
.overlayIframe {
  width: 97.5%;
  height: 100%;
}
.infoIcon {
  margin-left: 10px;
}
.tooltip {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 99;
}

.infoIcon:hover + .tooltip {
  display: block;
}
::-webkit-scrollbar {
  width: 10px;
  height: 14px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  background-color: rgb(249, 247, 247);
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7;
}
::-webkit-scrollbar-thumb:hover {
  background: #828181;
}
