
.custom-header {
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--background-color);
  padding: 10px;
  border-bottom: 1px solid var(--text-color);
}

.videoheading{
  /* font-size: var(--font-size); */
  font-size:2rem;
}
.videoSection {
  background: linear-gradient(
    269deg,
    rgba(11, 27, 55, 0.4) 1.07%,
    rgba(1, 10, 26, 0.4) 17.36%,
    rgba(31, 41, 61, 0) 98.79%
  );
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.16) inset;
  backdrop-filter: blur(50px);
  width: 100%; /* Responsive width */
  height: 50vh; /* Responsive height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure video doesn't overflow container */
}

.nextIconContainer {
  position: absolute;
  right: 2%; /* Adjust as needed */
  top: 50%; /* Adjust as needed */
  transform: translateY(-50%);
}

.backIconContainer {
  position: absolute;
  left: 2%; /* Adjust as needed */
  top: 50%; /* Adjust as needed */
  transform: translateY(-50%);
}

.content {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/Green_Solar_Background_1920x1080.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoheading {
  font-size: 2rem; /* Responsive font size */
  font-weight: 500;
  color: white;
  font-family: "Signika";
  margin: 10px 0; /* Adjust as needed */
}

.stepperContainer {
  width: 100%;
  max-width: 300px; /* Adjust as needed */
  margin-top: 20px; /* Adjust as needed */
}

@media screen and (max-width: 768px) {
  .videoheading {
    font-size: 1.5rem; /* Decrease font size on smaller screens */
  }

  .stepperContainer {
    max-width: 200px; /* Decrease max width on smaller screens */
  }
}
